import { routes } from '../api/routes';
import { useQuery } from '@tanstack/react-query';

const useMedical = () => {
  
  const { data, isLoading } = useQuery(['getMedicalRecords'], routes.getMedicalReport);
  const getConsultancyById = (id?: number) =>
    useQuery(['getConsultancyById', id], () => routes.getConsultancyById(id), {
      enabled: !!id
    });

  return {
    medicalRecords: data?.sort(
      (a, b) =>
        new Date(b.dateVisit).getTime() - new Date(a.dateVisit).getTime()
    ),
    loading: isLoading,
    getConsultancyById
  };
};

export default useMedical;
