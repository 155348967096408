import React from 'react';
import { Anchor, Button, Card, Center, Image, Stack, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useProvider from '../../hooks/use-provider';
import LoadingIndicator from '../loading-indicator';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import { getPath } from '../../pages/navigation';

const Provider = () => {
  const { t } = useTranslation(['default']);
  const { provider, loading } = useProvider();

  const providerLogoUrl = import.meta.env.VITE_PROVIDER_LOGO; // Use Vite's way of accessing env variables

  // Check if provider data is still loading
  if (loading) {
    return <LoadingIndicator />;
  }

  // Check if provider data is not yet available
  if (!provider) {
    return (
      <Text
        ta="center"
        mt="xl"
        mb="md"
        size={mantineConfig.mantine.text.fontSize}
        color={mantineConfig.mantine.text.color}
        style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
      >
        {t('tr.no-provider-selected')}
      </Text>
    );
  }

  // Destructure provider data safely
  const { logoURL = '', providerName = '', address = '', webPageUrl = '' } = provider;

  return (
    <Stack mt="md">
      <Center>
        <Card shadow="sm" p="xl" radius="md" w="500">
          <Card.Section>
            <Image
              src={providerLogoUrl || logoURL }
              height={350}
              alt="provider logo"
              withPlaceholder={true}
            />
          </Card.Section>
          <Text
            ta="center"
            mt="md"
            mb="md"
            color={mantineConfig.mantine.title.color}
            style={{
              fontFamily: mantineConfig.mantine.global.fontFamily,
              fontSize: mantineConfig.mantine.text.label.title.fontSize,
              fontWeight: mantineConfig.mantine.text.label.title.fontWeight,
            }}
          >
            {providerName}
          </Text>
          <Text
            ta="center"
            fw={500}
            fz={16}
            color={mantineConfig.mantine.text.label.title.color}
            style={{
              fontFamily: mantineConfig.mantine.global.fontFamily,
              fontSize: mantineConfig.mantine.text.label.fontSize,
              fontWeight: mantineConfig.mantine.text.label.fontWeight,
            }}
          >
            {address}
          </Text>
          {webPageUrl && (
            <Link to={webPageUrl} rel="noreferrer" target="_blank">
              <Button
                color={mantineConfig.mantine.button.disabled.backgroundColor}
                style={{
                  color: mantineConfig.mantine.button.fontColor,
                }}
                fullWidth
                mt="md"
                radius="md"
              >
                {t('tr.show-more-info')}
              </Button>
            </Link>
          )}
        </Card>
      </Center>
      <Text
        color={mantineConfig.mantine.text.color}
        weight={mantineConfig.mantine.text.href.fontWeight}
        style={{
          fontFamily: mantineConfig.mantine.global.fontFamily,
          fontSize: mantineConfig.mantine.text.href.fontSize,
        }}
        align="center"
      >
        {t('tr.contact-provider')}
        <Anchor
          color={mantineConfig.mantine.text.href.color}
          style={{
            fontFamily: mantineConfig.mantine.global.fontFamily,
            fontSize: mantineConfig.mantine.text.href.fontSize,
            fontWeight: mantineConfig.mantine.text.href.fontWeight,
          }}
          href={getPath('support')}
        >
          {' '}
          {t('tr.support')}
        </Anchor>{' '}
        {t('tr.team')}
      </Text>
    </Stack>
  );
};

export default Provider;
