export const supportOptions = [
	'booked_consultation_but_no_call_from_doctor',
	'change_healthcare_provider',
	'help',
	'can_not_add_child',
	'child_added_but_not_found',
	'created_child_account_but_can_not_see_my_child',
	'change_nationality_number',
	'can_not_update_profile',
	'error_while_booking_a_consultation_for_my_child',
	'change_password',
	'can_not_see_receipt',
	'can_not_see_my_consultation_history',
	'can_not_see_my_doctors_note',
	'can_not_see_drug_prescription',
	'can_not_see_follow_up',
	'can_not_see_lab_requests',
	'page_not_loading',
	'other',
];
