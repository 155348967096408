import {
	Box,
	Col,
	Flex,
	Grid,
	LoadingOverlay,
	Text,
	createStyles,
} from '@mantine/core';
import Container from '../layout/container';
import { useTranslation } from 'react-i18next';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import useMedical from '../../hooks/use-medical';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const MedicalItem = ({ id }: { id?: number }) => {
	const { getConsultancyById } = useMedical();
	const { data: medicalReport, isLoading } = getConsultancyById(id);
	const [imageSrc, setImageSrc] = useState('');

	useEffect(() => {
		const base64Image = medicalReport?.signature;
		setImageSrc(`data:image/png;base64,${base64Image}`);
	}, [medicalReport]);

	const { t } = useTranslation(['default']);
	const { classes } = useStyles();
    
	return (
		<Container>
			<LoadingOverlay visible={isLoading} />
			{medicalReport && (
				<Flex
					mt="xl"
					pos="relative"
					className={classes.boxClass}
					maw={1050}
					px="30px"
					w="100%"
					direction="column"
				>
					<Box
						p="md"
						bg="teal"
						w="100%"
						pos="absolute"
						className={classes.topBar}
					/>

					<Grid>
						<Col
							span={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: 30,
								marginTop: 30,
							}}
						>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('provider name').charAt(0).toUpperCase() +
										t('provider name').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.provideName}
								</Text>
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('purpose-visit').charAt(0).toUpperCase() +
										t('purpose-visit').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.purposeVisit}
								</Text>
							</div>
						</Col>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{/* {t('purpose-visit').charAt(0).toUpperCase() +
          t('purpose-visit').slice(1).toUpperCase()} */}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{/* {medicalReport?.purposeVisit} */}
								</Text>
							</div>
						</Col>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('date').charAt(0).toUpperCase() +
										t('date').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{dayjs(medicalReport?.dateVisit).format(
												'DD MMM YYYY, h:mm A'
											)}
								</Text>
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col span={12}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('product').charAt(0).toUpperCase() +
										t('product').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.healthCareProduct}
								</Text>
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col
							span={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: 30,
								marginTop: 30,
							}}
						>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('Medical Report').charAt(0).toUpperCase() +
										t('Medical Report').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								></Text>
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('patient name').charAt(0).toUpperCase() +
										t('patient name').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.patientName}
								</Text>
							</div>
						</Col>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('gender').charAt(0).toUpperCase() +
										t('gender').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.gender}
								</Text>
							</div>
						</Col>
						<Col span={4}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('age').charAt(0).toUpperCase() +
										t('age').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.age}
								</Text>
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col
							span={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: 30,
								marginTop: 30,
							}}
						>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
							
								>
									{t('description').charAt(0).toUpperCase() +
										t('description').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
									style={{
										maxWidth: '600px',  // Set the maximum width
										minWidth: '200px',  // Set the minimum width
										width: '100%',      // Ensure it takes up the full width of its container
										overflowWrap: 'break-word'  // Ensure text wraps properly
									}}
								>
									{medicalReport?.description}
								</Text>
							</div>
						</Col>
					</Grid>

                    {/* <Grid>
						<Col
							span={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								marginBottom: 30,
								marginTop: 30,
							}}
						>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('description').charAt(0).toUpperCase() +
										t('description').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.signature}
								</Text>
							</div>
						</Col>
					</Grid> */}

					<Grid>
						<Col span={12}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('signature').charAt(0).toUpperCase() +
										t('signature').slice(1).toUpperCase()}
								</Text>

								{imageSrc ? (
									<img
										src={imageSrc}
										alt={t('signature')}
										style={{ maxWidth: '20%', height: '100px' }}
									/>
								) : (
									<Text
										color={mantineConfig.mantine.text.color}
										size={mantineConfig.mantine.text.fontSize}
                                    	weight={mantineConfig.mantine.text.fontWeight}
                                      
									>
										{t('imageSrc')}
									</Text>
								)}
							</div>
						</Col>
					</Grid>

					<Grid>
						<Col span={12}>
							<div>
								<Text
									color={mantineConfig.mantine.title.color}
									size={mantineConfig.mantine.title.fontSize}
									weight={mantineConfig.mantine.title.fontWeight}
								>
									{t('Doctor').charAt(0).toUpperCase() +
										t('Doctor').slice(1).toUpperCase()}
								</Text>
								<Text
									color={mantineConfig.mantine.text.color}
									size={mantineConfig.mantine.text.fontSize}
									weight={mantineConfig.mantine.text.fontWeight}
								>
									{medicalReport?.docFullName}
								</Text>
							</div>
						</Col>
					</Grid>

				</Flex>
			)}
		</Container>
	);
};

const useStyles = createStyles((theme) => ({
	boxClass: {
		background: '#fff',
		borderRadius: 8,
		padding: 16,
		boxShadow: '0px 0px 5px #C0C0C0',
	},
	topBar: {
		borderTopRightRadius: theme.radius.md,
		borderTopLeftRadius: theme.radius.md,
		top: 0,
		left: 0,
	},
}));
