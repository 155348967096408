// Title - used in Patient Profile
export const TITLE_DATA = [
	{ value: 'Mr', label: 'mr' },
	{ value: 'Mrs', label: 'mrs' },
	{ value: 'Chief', label: 'chief' },
	{ value: 'Prof', label: 'prof' },
	{ value: 'Dr', label: 'dr' },
	{ value: 'Honorable', label: 'honorable' },
	{ value: 'Mzee', label: 'mzee' },
	{ value: 'Bi', label: 'bi' },
	{ value: 'Miss', label: 'miss' },
	{ value: 'Master', label: 'master' },
	{ value: 'Madam', label: 'madam' },
	{ value: 'Senator', label: 'senator' },
	{ value: 'President', label: 'president' },
	{ value: 'Speaker', label: 'speaker' },
	{ value: 'Councilor', label: 'councilor' },
	{ value: 'Governor', label: 'governor' },
	{ value: 'Ambassador', label: 'ambassador' },
	{ value: 'Bishop', label: 'bishop' },
	{ value: 'Mayor', label: 'mayor' },
	{ value: 'Engr.', label: 'engr.' },
	{ value: 'Surv', label: 'surv' },
	{ value: 'Dr. Alhaja Chief', label: 'dr-alhaja-chief' },
	{ value: 'Alhaja', label: 'alhaja' },
	{ value: 'Chief Dr.', label: 'chief-dr' },
	{ value: 'Eze', label: 'eze' },
	{ value: 'Igwe', label: 'igwe' },
	{ value: 'Oba', label: 'oba' },
	{ value: 'Alhaji', label: 'alhaji' },
	{ value: 'Sir', label: 'sir' },
	{ value: 'Dr. (Mrs)', label: 'dr(Mrs)' },
	{ value: 'Dr. (Mr)', label: 'dr(Mr)' },
	{ value: 'Alhaja Pharm Mrs', label: 'alhaja-pharm-mrs' },
	{ value: 'Engr. Double Chief Sir', label: 'engr-double-chief-sir' },
	{ value: 'Chief Dr. Sir', label: 'chief-dr-Sir' },
	{ value: 'Distinguished Senator', label: 'distinguished-senator' },
	{ value: 'Honorable Speaker.', label: 'honorable-speaker.' },
	{ value: 'His Excellency', label: 'his-excellency' },
	{ value: 'Pharm. Chief (Mrs.)', label: 'pharm-chief(Mrs.)' },
];

// Martial Status - used in Patient Profile
export const Martial_STATUS_DATA = [
	{ value: 'Married', label: 'Married' },
	{ value: 'Single', label: 'Single' },
	{ value: 'Divorced', label: 'Divorced' },
	{ value: 'Engaged', label: 'Engaged' },
];

// Blood types - used in Patient Profile
export const BLOOD_TYPES_DATA = [
	{ value: 'I dont know', label: 'i-dont-know' },
	{ value: 'A+', label: 'A+' },
	{ value: 'A-', label: 'A-' },
	{ value: 'B+', label: 'B+' },
	{ value: 'B-', label: 'B-' },
	{ value: 'O+', label: 'O+' },
	{ value: 'O-', label: 'O-' },
	{ value: 'AB+', label: 'AB+' },
	{ value: 'AB-', label: 'AB-' },
];

// Height in cm - used in Patient Profile
export const PATIENT_HEIGHT = [
	{ value: 50, label: '50cm' },
	{ value: 100, label: '100cm' },
	{ value: 150, label: '150cm' },
	{ value: 200, label: '200cm' },
	{ value: 250, label: '250cm' },
];

// Height in cm - used in Patient Profile
export const PATIENT_CHILD_HEIGHT = [
	{ value: 20, label: '20cm' },
	{ value: 70, label: '70cm' },
	{ value: 120, label: '120cm' },
	{ value: 170, label: '170cm' },
	{ value: 220, label: '220cm' },
	{ value: 250, label: '250cm' },
];

// Wight in kg - used in Patient Profile
export const PATIENT_WEIGHT = [
	{ value: 100, label: '100kg' },
	{ value: 200, label: '200kg' },
	{ value: 300, label: '300kg' },
	{ value: 400, label: '400kg' },
];

// Wight in kg - used in Patient Profile
export const PATIENT_CHILD_WEIGHT = [
	{ value: 1, label: '1kg' },
	{ value: 50, label: '50kg' },
	{ value: 100, label: '100kg' },
	{ value: 150, label: '150kg' },
	{ value: 200, label: '200kg' },
];

// Blood Pressure in mm - used in Patient Profile
export const PATIENT_BLOOD_PRESSURE = [
	{ value: 20, label: '20mm' },
	{ value: 90, label: '90mm' },
	{ value: 160, label: '160mm' },
	{ value: 230, label: '230mm' },
];

// Pulse in min - used in Patient Profile
export const PATIENT_PULSE = [
	{ value: 20, label: '20/min' },
	{ value: 90, label: '90/min' },
	{ value: 160, label: '160/min' },
	{ value: 230, label: '230/min' },
];

// RR in min - used in Patient Profile
export const PATIENT_RR = [
	{ value: 20, label: '20/min' },
	{ value: 50, label: '50/min' },
	{ value: 80, label: '80/min' },
	{ value: 110, label: '110/min' },
];

// SPO2 in % - used in Patient Profile
export const PATIENT_SPO = [
	{ value: 5, label: '5%' },
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 60, label: '60%' },
	{ value: 80, label: '80%' },
	{ value: 95, label: '95%' },
];

// Temperature in °C - used in Patient Profile
export const PATIENT_TEMPERATUR = [
	{ value: 10, label: '10°C' },
	{ value: 20, label: '20°C' },
	{ value: 30, label: '30°C' },
	{ value: 40, label: '40°C' },
];

// Blood Glucose in mg/dL - used in Patient Profile
export const PATIENT_GLUCOSE = [
	{ value: 20, label: '20/dL' },
	{ value: 90, label: '90/dL' },
	{ value: 160, label: '160/dL' },
	{ value: 230, label: '230/dL' },
];

// Preferred Language - used in Patient Profile
export const PREFERRED_LANGUAGE = [
	{ value: 'English', label: 'English' },
	{ value: 'Swahili', label: 'Swahili' },
	{ value: 'Igbo', label: 'Igbo' },
	{ value: 'Hausa', label: 'Hausa' },
	{ value: 'Yoruba', label: 'Yoruba' },
	{ value: 'Portuguese', label: 'Portuguese' },
	{ value: 'French', label: 'French' },
	{ value: 'Swedish', label: 'Swedish' },
	{ value: 'Finnish', label: 'Finnish' },
	{ value: 'Sami', label: 'Sami' },
	{ value: 'Welsh', label: 'Welsh' },
	{ value: 'Scots Gaelic', label: 'Scots Gaelic' },
	{ value: 'Irish', label: 'Irish' },
	{ value: 'Polish', label: 'Polish' },
	{ value: 'Punjabi', label: 'Punjabi' },
];
//  Languages - used in Patient Profile
export const LANGUAGES_NIGERIA = [
	{ value: 'English', label: 'English' },
	{ value: 'Pidgin English', label: 'Pidgin English' },
	{ value: 'Igbo', label: 'Igbo' },
	{ value: 'Hausa', label: 'Hausa' },
	{ value: 'Yoruba', label: 'Yoruba' },
];
export const LANGUAGES_ANGOLA = [
	{ value: 'Portuguese', label: 'Portuguese' },
	{ value: 'English', label: 'English' },
	{ value: 'French', label: 'French' },
];

export const LANGUAGES_SIERRALEONE = [
	{ value: 'English', label: 'English' },
	{ value: 'Krio', label: 'Krio' },
	{ value: 'French', label: 'French' },
];

export const LANGUAGES_SWEDEN = [
	{ value: 'English', label: 'English' },
	{ value: 'Swedish', label: 'Swedish' },
	{ value: 'Finnish', label: 'Finnish' },
	{ value: 'Sami', label: 'Sami' },
	{ value: 'Meänkieli', label: 'Meänkieli' },
	{ value: 'Romani', label: 'Romani' },
	{ value: 'Yiddish', label: 'Yiddish' },
	{ value: 'Arabic', label: 'Arabic' },
	{ value: 'Somali', label: 'Somali' },
	{ value: 'German', label: 'German' },
	];

export const LANGUAGES_NAMIBIA = [
	{ value: 'English', label: 'English' },
	{ value: 'Oshiwambo', label: 'Oshiwambo' },
	{ value: 'Khoekhoegowab    ', label: 'Khoekhoegowab' },
	{ value: 'Otjiherero', label: 'Otjiherero' },
	{ value: 'RuKwangali', label: 'RuKwangali' },
	{ value: 'siLozi', label: 'siLozi' },
	{ value: 'German', label: 'German' },
	{ value: 'San', label: 'San' },
];

export const LANGUAGES_TANZANIA = [
	{ value: 'English', label: 'English' },
	{ value: 'Swahili', label: 'Swahili' },
	{ value: 'Nyamwezi', label: 'Nyamwezi' },
	{ value: 'Sukuma', label: 'Sukuma' },
	{ value: 'Hehe', label: 'Hehe' },
	{ value: 'Chagga', label: 'Chagga' },
	{ value: 'Haya', label: 'Haya' },
	{ value: 'Gogo', label: 'Gogo' },
	{ value: 'Makonde', label: 'Makonde' },
	{ value: 'Ha', label: 'Ha' },
];

export const LANGUAGES_TOGO = [
	{ value: 'English', label: 'English' },
	{ value: 'French', label: 'French' },
	{ value: 'Ewe', label: 'Ewe' },
	{ value: 'Kabiye', label: 'Kabiye' },
	{ value: 'Tem', label: 'Tem' },
	{ value: 'Mina', label: 'Mina' },
	{ value: 'Dagomba', label: 'Dagomba' },
	{ value: 'Gurma', label: 'Gurma' },
	{ value: 'Aja', label: 'Aja' },
	{ value: 'Fon', label: 'Fon' },
	{ value: 'Moba', label: 'Moba' },
	];

export const LANGUAGES_GHANA = [
	{ value: 'English', label: 'English' },
	{ value: 'Akan', label: 'Akan' },
	{ value: 'Ewe', label: 'Ewe' },
	{ value: 'Dagabani', label: 'Dagabani' },
	{ value: 'Dangme', label: 'Dangme' },
	{ value: 'Ga', label: 'Ga' },
	{ value: 'Nzema', label: 'Nzema' },
	{ value: 'Gonja', label: 'Gonja' },
	{ value: 'Kasem', label: 'Kasem' },
	{ value: 'Pidgin', label: 'Pidgin' },
];

export const LANGUAGES_RWANDA = [
	{ value: 'English', label: 'English' },
	{ value: 'Kinyarwanda', label: 'Kinyarwanda' },
	{ value: 'French', label: 'French' },
	{ value: 'Swahili', label: 'Swahili' },
];

export const LANGUAGES_UK = [
	{ value: 'English', label: 'English' },
	{ value: 'Welsh', label: 'Welsh' },
	{ value: 'Scots Gaelic', label: 'Scots Gaelic' },
	{ value: 'Irish', label: 'Irish' },
	{ value: 'Polish', label: 'Polish' },
	{ value: 'Punjabi', label: 'Punjabi' },
	{ value: 'Urdu', label: 'Urdu' },
	{ value: 'Bengali', label: 'Bengali' },
	{ value: 'Gujarati', label: 'Gujarati' },
	{ value: 'Arabic', label: 'Arabic' },
	];

export const LANGUAGES = {
	Nigeria: LANGUAGES_NIGERIA,
	Angola: LANGUAGES_ANGOLA,
	'Sierra Leone': LANGUAGES_SIERRALEONE,
	Namibia: LANGUAGES_NAMIBIA,
	Tanzania: LANGUAGES_TANZANIA,
	Ghana: LANGUAGES_GHANA,
	Rwanda: LANGUAGES_RWANDA,
	Togo: LANGUAGES_TOGO,
	Sweden: LANGUAGES_SWEDEN,
	UK: LANGUAGES_UK
};
// Country Data  - used in Patient Profile
export const COUNTRY = [
	{ value: 'Angola', label: 'Angola' },
	{ value: 'Ghana', label: 'Ghana' },
	{ value: 'Nigeria', label: 'Nigeria' },
	{ value: 'Tanzania', label: 'Tanzania' },
	{ value: 'Rwanda', label: 'Rwanda' },
	{ value: 'Namibia', label: 'Namibia' },
	{ value: 'Sierra Leone', label: 'Sierra Leone' },
	{ value: 'Sweden', label: 'Sweden' },
	{ value: 'Togo', label: 'Togo' },
	{ value: 'UK', label: 'UK' },
];

// Patient/Child disabilities -  used in Patient Profile
export const PATIENT_DISABILITIES = [
	{ value: 'None', label: 'none' },
	{ value: 'Vision impairment', label: 'vision-impairment' },
	{ value: 'Deaf or hard of hearing', label: 'deaf-or-hard-of-hearing' },
	{ value: 'Mental health conditions', label: 'mental-health-conditions' },
	{ value: 'Intellectual disability', label: 'intellectual-disability' },
	{ value: 'Acquired brain injury', label: 'acquired-brain-injury' },
	{ value: 'Autism spectrum disorder', label: 'autism-spectrum-disorder' },
	{ value: 'Physical disability', label: 'physical-disability' },
];

// Child gender -  used in Patient Profile in modal
export const CHILD_GENDER = [
	{ value: 'Male', label: 'Male' },
	{ value: 'Female', label: 'Female' },
];

// Patient/Child allergies -  used in Patient Profile
export const PATIENT_ALLERGIES = [
	{ value: 'None', label: 'none' },
	{ value: 'Drug allergy', label: 'drug-allergy' },
	{ value: 'Food allergy', label: 'food-allergy' },
	{ value: 'Insect allergy', label: 'insect-allergy' },
	{ value: 'Latex allergy', label: 'latex-allergy' },
	{ value: 'Mold allergy', label: 'mold-allergy' },
	{ value: 'Pet allergy', label: 'pet-allergy' },
	{ value: 'Pollen allergy', label: 'pollen-allergy' },
	{ value: 'Skin allergy', label: 'skin-allergy' },
	{ value: 'Dust allergies', label: 'dust-allergies' },
	{ value: 'Eye allergy', label: 'eye-allergy' },
	{ value: 'Insect sting allergies', label: 'insect-sting-allergies' },
	{ value: 'Hay fever', label: 'hay-fever' },
	{ value: 'Cockroach allergy', label: 'cockroach-allergy' },
	{ value: 'Mold allergies', label: 'mold-allergies' },
	{ value: 'Ragweed allergy', label: 'ragweed-allergy' },
	{ value: 'Seasonal allergies', label: 'seasonal-allergies' },
	{ value: 'Sinus infection', label: 'sinus-infection' },
	{ value: 'Nasal polyps', label: 'nasal-polyps' },
	{ value: 'Chlorine allery', label: 'chlorine-allery' },
	{ value: 'Pine tree allergy', label: 'pine-tree-allergy' },
	{ value: 'Eosinophilic esophagitis', label: 'eosinophilic-esophagitis' },
];

// Patient Medical Problems - used in Patient Profile
export const PATIENT_MEDICAL_PROBLEMS = [
	'none',
	'abdominal-aortic-aneurysm',
	'abdominal-cramps',
	'abdominal-discomfort',
	'abdominal-pain',
	'acne',
	'acute-cholecystitis',
	'acute-lymphoblastic-leukaemia',
	'acute-lymphoblastic-leukaemia-children',
	'acute-lymphoblastic-leukaemia-teenagers-and-young-adults',
	'acute-myeloid-leukaemia',
	'acute-myeloid-leukaemia-children',
	'acute-myeloid-leukaemia-teenagers-and-young-adults',
	'acute-pancreatitis',
	'addisons-disease',
	'alcohol-related-liver-disease',
	'allergic-rhinitis',
	'allergies',
	'alzheimers-disease',
	'anal-cancer',
	'anaphylaxis',
	'angioedema',
	'ankylosing-spondylitis',
	'anorexia-nervosa',
	'anxiety',
	'anxiety-disorders-in-children',
	'appendicitis',
	'arthritis',
	'asbestosis',
	'asthma',
	'atopic-eczema',
	'attention-deficit-hyperactivity-disorder-adhd',
	'autistic-spectrum-disorder-asd',
	'bacterial-vaginosis',
	'benign-prostate-enlargement',
	'bile-duct-cancer-cholangiocarcinoma',
	'binge-eating',
	'bipolar-disorder',
	'bladder-cancer',
	'blood-poisoning-sepsis',
	'bone-cancer',
	'bone-cancer-teenagers-and-young-adults',
	'bowel-cancer',
	'bowel-incontinences',
	'bowel-polyps',
	'brain-stem-deathy',
	'brain-tumourss',
	'brain-tumours-children',
	'brain-tumours-teenagers-and-young-adults',
	'breast-cancer-female',
	'breast-cancer-male',
	'bronchiectasis',
	'bronchitis',
	'bulimia',
	'bunion',
	'carcinoid-syndrome-and-carcinoid-tumours',
	'catarrh',
	'cellulitis',
	'cervical-cancer',
	'chest-infection',
	'chest-pain',
	'chickenpox',
	'chilblains',
	'chlamydia',
	'chills',
	'chronic-fatigue-syndrome',
	'chronic-kidney-diseases',
	'chronic-lymphocytic-leukaemia',
	'chronic-myeloid-leukaemia',
	'chronic-obstructive-pulmonary-disease',
	'chronic-pancreatitis',
	'cirrhosis',
	'clostridium-difficile',
	'coeliac-disease',
	'cold-sore',
	'coma',
	'common-cold',
	'common-heart-conditions',
	'congenital-heart-disease',
	'conjunctivitis',
	'constipation',
	'coronavirus-covid-19',
	'cough',
	'crohns-disease',
	'croup',
	'cystic-fibrosis',
	'cystitis',
	'deafblindness',
	'deep-vein-thrombosis',
	'dehydration',
	'dementia',
	'dementia-with-lewy-bodies',
	'dental-abscess',
	'depression',
	'dermatitis-herpetiformis',
	'diabetes',
	'difficulty-in-breathing',
	'diarrhea',
	'discoid-eczema',
	'diverticular-disease-and-diverticulitis',
	'dizziness-lightheadedness',
	'downs-syndrome',
	'dry-mouth',
	'dysphagia-swallowing-problems',
	'dystonia',
	'ear-ache',
	'earwax-build-up',
	'ebola-virus-disease',
	'ectopic-pregnancy',
	'endometriosis',
	'epilepsy',
	'erectile-dysfunction-impotence',
	'escherichia-coli-e-coli-o157',
	'ewing-sarcoma',
	'ewing-sarcoma-children',
	'exudation-of-pus',
	'eye-cancer',
	'eye-irritation',
	'febrile-seizures',
	'fever',
	'fever-in-adults',
	'fever-in-children',
	'fibroids',
	'fibromyalgia',
	'flatulence',
	'flu',
	'foetal-alcohol-syndrome',
	'food-poisoning',
	'fungal-nail-infection',
	'gallbladder-cancer',
	'gallstones',
	'ganglion-cyst',
	'gastroenteritis',
	'gastro-oesophageal-reflux-disease-gord',
	'genital-herpes',
	'genital-warts',
	'germ-cell-tumours',
	'glandular-fever',
	'gonorrhoea',
	'gout',
	'gum-disease',
	'haemorrhoids-piles',
	'hairy-cell-leukaemia',
	'hand-foot-and-mouth-disease',
	'hay-fever',
	'head-and-neck-cancer',
	'head-lice-and-nits',
	'headaches',
	'hearing-loss',
	'heart-failure',
	'hepatitis-a',
	'hepatitis-b',
	'hepatitis-c',
	'hiatus-hernia',
	'high-cholesterol',
	'hiv',
	'hodgkin-lymphoma',
	'hodgkin-lymphoma-children',
	'hodgkin-lymphoma-teenagers-and-young-adults',
	'huntingtons-disease',
	'hyperglycaemia-high-blood-sugar',
	'hyperhidrosis',
	'hypoglycaemia-low-blood-sugar',
	'idiopathic-pulmonary-fibrosis',
	'indigestion',
	'ingrown-toenail',
	'inherited-heart-conditions',
	'insomnia',
	'iron-deficiency-anaemia',
	'irritable-bowel-syndrome-ibs',
	'irritable-hip',
	'itching',
	'itchy-bottom',
	'kaposis-sarcoma',
	'impetigo',
	'kidney-cancer',
	'kidney-infection',
	'kidney-stones',
	'labyrinthitis',
	'lactose-intolerance',
	'langerhans-cell-histiocytosis',
	'laryngeal-larynx-cancer',
	'laryngitis',
	'leg-cramps',
	'lichen-planus',
	'liver-cancer',
	'liver-disease',
	'liver-tumours',
	'loss-of-libido',
	'lung-cancer',
	'lupus',
	'lyme-disease',
	'lymphoedema',
	'lymphogranuloma-venereum-lgv',
	'malaria',
	'malignant-brain-tumour-cancerous',
	'malnutrition',
	'measles',
	'meningitis',
	'menopause',
	'mesothelioma',
	'middle-ear-infection-otitis-media',
	'migraine',
	'miscarriage',
	'motor-neurone-disease-mnd',
	'mouth-cancer',
	'mouth-ulcer',
	'multiple-myeloma',
	'multiple-sclerosis-ms',
	'mumps',
	'menieres-disease',
	'nasal-and-sinus-cancer',
	'nasopharyngeal-cancer',
	'neuroblastoma',
	'neuroblastoma-children',
	'neuroendocrine-tumours',
	'noisy-breathing',
	'non-alcoholic-fatty-liver-disease-nafld',
	'non-hodgkin-lymphoma',
	'non-hodgkin-lymphoma-children',
	'norovirus',
	'nosebleed',
	'obesity',
	'obsessive-compulsive-disorder-ocd',
	'obstructive-sleep-apnoea',
	'oesophageal-cancer',
	'oral-thrush-in-adults',
	'osteoarthritis',
	'osteoporosis',
	'osteosarcoma',
	'otitis-externa',
	'ovarian-cancer',
	'ovarian-cancer-teenagers-and-young-adults',
	'ovarian-cyst',
	'overactive-thyroid',
	'pagets-disease-of-the-nipple',
	'palpitations',
	'pancreatic-cancer',
	'panic-disorder',
	'parkinsons-disease',
	'pelvic-discomfort',
	'pelvic-inflammatory-disease',
	'pelvic-organ-prolapse',
	'pelvic-pain',
	'pelvic-pressure',
	'penile-cancer',
	'peripheral-neuropathy',
	'personality-disorder',
	'pink-eye',
	'pleurisy',
	'pneumonia',
	'polymyalgia-rheumatica',
	'post-traumatic-stress-disorder-ptsd',
	'postnatal-depression',
	'pregnancy-and-baby',
	'pressure-ulcers',
	'prostate-cancer',
	'psoriasis',
	'psoriatic-arthritis',
	'psychosis',
	'pubic-lice',
	'rare-tumours',
	'rashes',
	'raynauds-phenomenon',
	'reactive-arthritis',
	'restless-legs-syndrome',
	'retinoblastoma',
	'retinoblastoma-children',
	'rhabdomyosarcoma',
	'rheumatoid-arthritis',
	'ringing-ears',
	'ringworm-and-other-fungal-infections',
	'rosacea',
	'scabies',
	'scarlet-fever',
	'schizophrenia',
	'scoliosis',
	'septic-shock',
	'shingles',
	'shortness-of-breath',
	'sickle-cell-disease',
	'sinusitis',
	'sjogrens-syndrome',
	'skin-cancer-melanoma',
	'skin-cancer-non-melanoma',
	'slapped-cheek-syndrome',
	'soft-tissue-sarcomas',
	'soft-tissue-sarcomas-teenagers-and-young-adults',
	'sore-throat',
	'spleen-problems-and-spleen-removal',
	'stillbirth',
	'stomach-ache-and-abdominal-pain',
	'stomach-cancer',
	'stomach-ulcer',
	'stress-anxiety-and-low-mood',
	'stroke',
	'sudden-infant-death-syndrome-sids',
	'suicide',
	'sunburn',
	'swollen-glands',
	'syphilis',
	'tachypnea',
	'testicular-cancer',
	'testicular-cancer-teenagers-and-young-adults',
	'testicular-lumps-and-swellings',
	'thirst',
	'threadworms',
	'thrush',
	'thrush-in-men',
	'thyroid-cancer',
	'thyroid-cancer-teenagers-and-young-adults',
	'tinnitus',
	'tonsillitis',
	'tooth-decay',
	'toothache',
	'transient-ischaemic-attack-tia',
	'trigeminal-neuralgia',
	'tuberculosis-tb',
	'type-1-diabetes',
	'type-2-diabetes',
	'trichomonas-infection',
	'ulcerative-colitis',
	'underactive-thyroid',
	'urinary-incontinence',
	'urinary-tract-infection-uti',
	'urinary-tract-infection-uti-in-children',
	'urticaria-hives',
	'vaginal-cancer',
	'vaginal-thrush',
	'varicose-eczema',
	'venous-leg-ulcer',
	'vertigo',
	'vitamin-b12-or-folate-deficiency-anaemia',
	'vomiting-in-adults',
	'vulval-cancer',
	'warts-and-verrucas',
	'whooping-cough',
	'wilms-tumour',
	'womb-uterus-cancer',
	'yellow-fever',
];
