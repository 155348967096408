import french from './fr.png';
import swahili from './sw.png';
import english from './en.png';
import swedish from './se.png';
import afrikaans from './za.png';
import german from './de.png';
import spanish from './es.png';
import akan from './gh.png';
import portugal from './pt.png';
import ewe from './ew.png';
import nigeria from './ngn.png';

export default {
	english: english,
	swedish: swedish,
	french: french,
	swahili: swahili,
	nigeria: nigeria,
	afrikaans: afrikaans,
	akan: akan,
	german: german,
	portugal: portugal,
	spanish: spanish,
	ewe: ewe,
};
