import PageTitle from '../../components/core/page-title';
import Container from '../../components/layout/container';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MedicalItem } from '../../components/summary/medical-item';

export const MedicalReceipt = () => {
	const { t } = useTranslation(['default']);
	const { consultationId } = useParams();
	return (
		<Container>
			<PageTitle heading={`${t('medical-receipt').toUpperCase()}`} />
			<MedicalItem  />
		</Container>
	);
};
