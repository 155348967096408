import Container from '../../components/layout/container';
import { Fragment } from 'react';
import PageTitle from '../../components/core/page-title';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatUrlParamsToNumber } from '../../utils';
import { MedicalItem } from '../../components/summary/medical-item';

export const MedicalDetail = () => {
	const { reportId } = useParams();
	const { t } = useTranslation(['default']);
	return (
		<Fragment>
			<Container sx={{ flex: '1', justifyContent: 'flex-start' }}>
				<PageTitle heading={"Medical Info"} />
				<MedicalItem id={formatUrlParamsToNumber(reportId)} />
			</Container>
		</Fragment>
	);
};
