import { routes } from '../api/routes';
import { useQuery } from '@tanstack/react-query';

const useSummary = () => {
	const { data, isLoading } = useQuery(['getSummaries'], routes.getSummaries);

	const getSummaryById = (id?: number) =>
		useQuery(['getConsultationSummaryById', id], () => routes.getSummary(id), {
			enabled: !!id,
		});
	const getPrescriptionById = (id?: number) =>
		useQuery(
			['getPrescriptions', id],
			() => routes.getPrescriptionsByConsultation(id),
			{
				enabled: !!id,
			}
		);
	const getLabRequestsById = (id?: number) =>
		useQuery(['getLabresult', id], () => routes.getLabResults(id), {
			enabled: !!id,
		});

	const getMedicalReportByConsultancyId = (id?: number) =>
		useQuery(['getConsultancyById', id], () => routes.getConsultancyById(id), {
			enabled: !!id,
		});
	return {
		summaries: data,
		loading: isLoading,
		getPrescriptionById,
		getSummaryById,
		getLabRequestsById,
		getMedicalReportByConsultancyId
	};
};

export default useSummary;
