export const titles = [
	'mr',
	'mrs',
	'chief',
	'prof',
	'dr',
	'honorable',
	'mzee',
	'bi',
	'miss',
	'master',
	'madam',
	'senator',
	'president',
	'speaker',
	'councilor',
	'governor',
	'ambassador',
	'bishop',
	'mayor',
	'engr.',
	'surv',
	'dr-alhaja-chief',
	'alhaja',
	'chief-dr.',
	'eze',
	'igwe',
	'oba',
	'alhaji',
	'sir',
	'dr(Mrs)',
	'dr(Mr)',
	'alhaja-pharm-mrs',
	'engr-double-chief-sir',
	'chief-dr-Sir',
	'distinguished-senator',
	'honorable-speaker.',
	'his-excellency',
	'pharm-chief(Mrs.)',
	'arc',
];
