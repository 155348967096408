import {
	Avatar,
	Button,
	Card,
	Flex,
	Image,
	Pagination,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import empty from '../../assets/images/empty.svg'; // Make sure this image is relevant for MedicalList
import Container from '../layout/container';
import { IconListSearch, IconSearch } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import useMedical from '../../hooks/use-medical'; // Assume useMedical is a custom hook for fetching medical data
import { getPath } from '../../pages/navigation';
import { useFilter } from '../../hooks/use-filter';
import { useDebouncedValue } from '@mantine/hooks';
import mantineConfig from '../../assets/styles/config/mantine.config.json';
import PageTitle from '../core/page-title';
import { formatDate } from '../../utils';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import locale if needed
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CARDS_PER_PAGE = 6;

const MedicalList = () => {
	const { t } = useTranslation(['default']);
	const { medicalRecords, loading } = useMedical(); // Fetch medical records
	const [search, setSearch] = useState<string>('');
	const [query] = useDebouncedValue<string>(search, 500);
	const [cardPerPage] = useState(5);
	const [activePage, setPage] = useState(1);
	const indexOfLastCard = activePage * cardPerPage;
	const indexOfFirstCard = indexOfLastCard - cardPerPage;
	// const pageNumbers = (result && result?.length / CARDS_PER_PAGE) || 1;

	const { data: result } = useFilter({
		data: medicalRecords || [],
		search: {
			query,
			enableHighlighting: true,
			fields: ['doctorName'], // Adjust fields if needed
		},
	});

	const filteredData = medicalRecords?.filter((consultation) =>
		(consultation?.doctorName + formatDate(consultation?.timeFinished))
			.toLowerCase()
			.toString()
			.includes(query.toLocaleLowerCase())
	);

	useEffect(() => {
		if (query.length >= 1) {
			setPage(1);
		}
	}, [query]);

	const pageNumbers = [];
	for (
		let i = 0;
		i < Math.ceil(filteredData ? filteredData?.length / cardPerPage : 1);
		i++
	) {
		pageNumbers.push(i);
	}

	return (
		<>
			<Container>
				<PageTitle heading={'Medical Reports'} />
				{/* {medicalRecords && medicalRecords?.length > CARDS_PER_PAGE && ( */}
				{!loading && medicalRecords && medicalRecords?.length > cardPerPage && (
					<Flex
						mih={70}
						gap="xs"
						w="100%"
						justify="center"
						align="center"
						direction="row"
						wrap="wrap"
					>
						<TextInput
							icon={<IconSearch size={18} />}
							placeholder="Search by doctor's name"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							radius="xl"
						/>
					</Flex>
				)}
				{!loading && filteredData
					? filteredData
							?.map((consultation) => (
								<Card
									withBorder={true}
									radius="md"
									padding="xl"
									sx={(theme) => ({
										backgroundColor: '#F8F9FA',
										transition: 'transform 200ms ease, box-shadow 100ms ease',
										'&:hover': {
											backgroundColor: 'rgb(247, 247, 247)',
											boxShadow: theme.shadows.md,
											transform: 'scale(1.005)',
											'&::before': {
												content: '""',
												position: 'absolute',
												top: 0,
												bottom: 0,
												left: 0,
												width: 6,
												backgroundImage: theme.fn.linearGradient(
													2,
													theme.colors.teal[9],
													theme.colors.teal[5]
												),
											},
										},
									})}
									maw={980}
									w={'100%'}
									key={consultation.consultancyId}
									mt={20}
									p="md"
									component={Link}
									to={getPath('reports', [
										consultation?.consultancyId.toString(),
									])}
								>
									<Flex justify="space-between" align="center">
										<Text
											color={mantineConfig.mantine.title.color}
											size="0.9rem"
											weight={mantineConfig.mantine.title.fontWeight}
										>
											{consultation?.docFullName}
										</Text>
										<Text size="sm" align="right">
											{consultation?.dateVisit
												? dayjs(consultation.dateVisit)
														.tz(dayjs.tz.guess())
														.format('DD MMM YYYY, HH:mm')
												: 'No date available'}
										</Text>
									</Flex>

									<Flex justify="space-between" align="center" gap="40px">
										<Text
											color={mantineConfig.mantine.title.color}
											size={mantineConfig.mantine.title.fontSize}
											weight={mantineConfig.mantine.title.fontWeight}
										>
											Dr.{' '}
											<Text
												span={true}
												color={mantineConfig.mantine.title.color}
												size={mantineConfig.mantine.title.fontSize}
												weight={mantineConfig.mantine.title.fontWeight}
												// rome-ignore lint: dangerouslySetInnerHTML is used to highlight the search result
												dangerouslySetInnerHTML={{
													__html: consultation?.docFullName,
												}}
											/>
										</Text>
									</Flex>
								</Card>
							))
							?.slice(indexOfFirstCard, indexOfLastCard)
					: null}

				{medicalRecords && medicalRecords?.length === 0 && (
					<Flex
						mt="3rem"
						gap="2rem"
						direction="column"
						align="center"
						justify="center"
					>
						<Image
							style={{ width: 240, marginLeft: 'auto', marginRight: 'auto' }}
							radius="md"
							src={empty}
							alt="No Medical Records Found" // Adjust alt text if needed
						/>
						<Title align="center" order={3}>
							{t('There is no medical record found!')}{' '}
							{/* Adjust translation key if needed */}
						</Title>
						<Link to={getPath('booking')}>
							<Button> {t('Book medical appointment here')}</Button>{' '}
							{/* Adjust translation key if needed */}
						</Link>
					</Flex>
				)}

				{query.length > 0 && result.length < 1 && (
					<Flex mt="2.5rem" direction="column" align="center" justify="center">
						<Avatar size="xl" color="red" radius={50} my={30}>
							<IconListSearch size="4rem" />
						</Avatar>
						<Text
							align="center"
							mb={30}
							color={mantineConfig.mantine.title.color}
							size={mantineConfig.mantine.title.fontSize}
							weight={mantineConfig.mantine.title.fontWeight}
						>
							{t('tr.there-is-no-medical-records-for-this-day')}{' '}
							{/* Adjust translation key if needed */}
							<Text
								style={{ fontFamily: mantineConfig.mantine.global.fontFamily }}
								color={mantineConfig.mantine.text.color}
								size={mantineConfig.mantine.text.fontSize}
								weight={mantineConfig.mantine.text.fontWeight}
							>
								{t(
									'tr.please-make-sure-you-have-entered-the-correct-information'
								)}
							</Text>
						</Text>
					</Flex>
				)}
			</Container>

			{!loading && filteredData && filteredData?.length > cardPerPage ? (
				<Pagination
					mt={40}
					mb={50}
					mx={20}
					position="center"
					noWrap={false}
					value={activePage}
					onChange={setPage}
					total={pageNumbers.length}
				/>
			) : null}
		</>
	);
};

export default MedicalList;
